<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.818" x2="0.246" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_reservation" transform="translate(-1438 -2355)">
    <rect :fill="background" data-name="Rectangle 186" height="183" id="Rectangle_186" transform="translate(1438 2355)" width="183"/>
    <path d="M87.5,32.111H2.408A2.408,2.408,0,0,1,0,29.7V22.478a9.633,9.633,0,0,1,9.633-9.633h9.633V2.408A2.408,2.408,0,0,1,21.675,0H29.7a2.408,2.408,0,0,1,2.408,2.408V12.845H57.8V2.408A2.408,2.408,0,0,1,60.209,0h8.028a2.408,2.408,0,0,1,2.408,2.408V12.845h9.633a9.633,9.633,0,0,1,9.633,9.633V29.7A2.408,2.408,0,0,1,87.5,32.111Zm-85.1,6.422H87.5a2.408,2.408,0,0,1,2.408,2.408V93.123a9.633,9.633,0,0,1-9.633,9.633H9.633A9.633,9.633,0,0,1,0,93.123V40.942A2.408,2.408,0,0,1,2.408,38.534ZM69.3,57.79l-5.653-5.7a2.408,2.408,0,0,0-3.406-.014L38.959,73.187l-9.228-9.3a2.408,2.408,0,0,0-3.406-.014l-5.7,5.654a2.408,2.408,0,0,0-.014,3.406L37.19,89.642a2.408,2.408,0,0,0,3.406.014L69.286,61.2A2.408,2.408,0,0,0,69.3,57.79Z" data-name="Icon awesome-calendar-check" fill="url(#linear-gradient)" id="Icon_awesome-calendar-check" transform="translate(1484.544 2395.122)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>